<template>
    <div>
        <v-app>
            <v-card>
                <v-card-title>
                    Endpoints habilitados
                    <v-spacer></v-spacer>
                    <v-btn class="" @click="fetchData"  text icon color="blue">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    <div class="mx-4">
                        <v-btn @click="$emit('openDialogCrearEndpoint')" color="success" icon dark >
                            <v-icon>icon-new_action-2</v-icon>
                        </v-btn>
                    </div>
                </v-card-title>
                <v-card-text >
                    <div class="d-flex justify-space-between mb-4">
                    <v-text-field
                        v-model="search"
                        clearable
                        label="Buscar"
                        hide-details
                        dense
                        solo
                        class="mr-3"
                    ></v-text-field>
                    <v-btn class="btn-starkoms-primary" style="min-width: 138px;" dark>Buscar</v-btn>
                    </div>
                </v-card-text>
                <v-card-text>
                    <v-data-table
                        :headers="headers"
                        :items="tableData"
                        :page.sync="page"
                        :items-per-page="itemsPerPage"
                        :search="search"
                        :single-select="true"
                        show-select
                        v-model="selected"
                        no-results-text="No hay resultados disponibles"
                        no-data-text="No hay datos disponibles"
                        loading-text="Cargando datos..."
                        class="elevation-1 datatable"
                    >
                        <template v-slot:item.acciones="{ item }">
                            <td>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                    <v-icon
                                        small
                                        color="#93a2dd"
                                        class="mr-2"
                                        v-on="on"
                                        @click="abrirDialogEditarEndpoint(item.id)"
                                    >mdi-pencil</v-icon>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>
                            </td>
                        </template>
                        <template v-slot:item.store="{ item }">
                            <td>
                                {{item.store.name}}
                            </td>
                        </template>
                        <template v-slot:item.logistic="{ item }">
                            <td>
                                {{item.logistic.name}}
                            </td>
                        </template>
                        <template v-slot:item.created_at="{ item }">
                            <td>
                                {{$moment(item.created_at).format('DD-MM-YYYY')}}
                            </td>
                        </template>
                    </v-data-table>
                    <div class="text-center pt-2">
                        <v-pagination v-model="page" :length="pageCount" :total-visible="10"></v-pagination>
                    </div>
                </v-card-text>
            </v-card>
        </v-app>
        <dialog-crear-endpoint @success="fetchData"></dialog-crear-endpoint>
        <dialog-editar-endpoint @success="fetchData"></dialog-editar-endpoint>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import DialogCrearEndpoint from '@/views/oms/Configuraciones/DialogCrearEndpoint.vue';
import DialogEditarEndpoint from '@/views/oms/Configuraciones/DialogEditarEndpoint.vue';
export default {
    components:{
        DialogCrearEndpoint,
        DialogEditarEndpoint
    },
    data(){
        return{
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            search: '',
            selected: [],
            headers: [
                { text: 'ID', align: 'left', sortable: true, value: 'id'},
                { text: 'Endpoint', value: 'endpoint' },
                { text: 'Store', value: 'store' },
                { text: 'Logistic', value: 'logistic' },
                { text: 'Tipo', value: 'type' },
                { text: 'Fecha creación', value: 'created_at' },
                { text: 'Acciones', value: 'acciones' },
            ],
            tableData: [],
        }
    },
    methods:{
        abrirDialogEditarEndpoint(){
            let item = this.selected[0];
            this.$emit('openDialogEditarEndpoint', item);
        },
        openDialogCrearEndpoint(){
            this.$emit('openDialogCrearEndpoint');
        },
        fetchData()
        {
            var vm = this;
            this.axios({
                url: 'orders/configurations/endpoints',
                method: 'GET',

            }).then( response => {
                /**Pagination */
                vm.pageCount = Math.ceil(response.data.length / 10);
                vm.tableData = response.data;
            }).catch( error => {
                console.log(error);
            })
        }
    },
    mounted(){
        this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Endpoints habilitados", route: "configuraciones/endpoints-habilitados" },
        ]);
        this.fetchData();
    }
}
</script>