<template>
    <v-dialog v-model="showDialog" max-width="500px">
        <v-card v-if="item">
            <v-card-title>
                <span>Crear Endpoint</span>
            </v-card-title>
            <v-card-text>
                <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                >
                    <v-text-field
                        v-model="item.endpoint"
                        :counter="200"
                        label="Endpoint"
                        required
                    ></v-text-field>
                    <v-select :items="logisticsList" v-model="item.logistic_id" label="Logistic asociado">
                    </v-select>
                    <v-select :items="storesList" v-model="item.store_id" label="Store asociado">
                    </v-select>
                    <v-select :items="['UPDATE_ORDER_STATE']" v-model="item.type" label="Tipo">
                    </v-select>
                    <br>
                    <v-btn :disabled="!item.endpoint || !item.logistic_id || !item.store_id || !item.type" @click="sendForm" ><v-icon left>mdi-content-save</v-icon> Guardar</v-btn>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary"
                    text
                    @click="showDialog = false"
                    >
                    Cancelar
                </v-btn>
                <v-btn
                    color="success"
                    text
                    @click="sendForm"
                    >
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data(){
        return {
            item: {
                logistic_id: undefined,
                store_id: undefined,
                type: undefined,
                endpoint: undefined,
            },
            showDialog: false,
            storesList: [],
            logisticsList: [],
            valid: true,
        }
    },
    created(){
        this.$parent.$on('openDialogCrearEndpoint', () => {
            this.showDialog = true;
            this.fetchStoresList();
            this.fetchLogisticsList();
        });
    },
    mounted(){
        
    },
    methods:{
        fetchStoresList(){
            var vm = this;
            this.axios({
                url: 'orders/stores/data_select_input',
                method: 'GET',
            }).then(response => { vm.storesList = response.data; });
        },
        fetchLogisticsList(){
            var vm = this;
            this.axios({
                url: 'orders/logistics/data_select_input',
                method: 'GET',
            }).then(response => { vm.logisticsList = response.data; });
        },
        sendForm(){
            var vm = this;
            this.axios({
                url: 'orders/configurations/endpoints',
                method: 'POST',
                data: this.item 
            }).then( response => { vm.$emit('success'); vm.showDialog = false; })
        }
    }
}
</script>