var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app',[_c('v-card',[_c('v-card-title',[_vm._v(" Endpoints habilitados "),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","icon":"","color":"blue"},on:{"click":_vm.fetchData}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('div',{staticClass:"mx-4"},[_c('v-btn',{attrs:{"color":"success","icon":"","dark":""},on:{"click":function($event){return _vm.$emit('openDialogCrearEndpoint')}}},[_c('v-icon',[_vm._v("icon-new_action-2")])],1)],1)],1),_c('v-card-text',[_c('div',{staticClass:"d-flex justify-space-between mb-4"},[_c('v-text-field',{staticClass:"mr-3",attrs:{"clearable":"","label":"Buscar","hide-details":"","dense":"","solo":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"btn-starkoms-primary",staticStyle:{"min-width":"138px"},attrs:{"dark":""}},[_vm._v("Buscar")])],1)]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 datatable",attrs:{"headers":_vm.headers,"items":_vm.tableData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"search":_vm.search,"single-select":true,"show-select":"","no-results-text":"No hay resultados disponibles","no-data-text":"No hay datos disponibles","loading-text":"Cargando datos..."},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"#93a2dd"},on:{"click":function($event){return _vm.abrirDialogEditarEndpoint(item.id)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1)]}},{key:"item.store",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.store.name)+" ")])]}},{key:"item.logistic",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.logistic.name)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format('DD-MM-YYYY'))+" ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":10},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1),_c('dialog-crear-endpoint',{on:{"success":_vm.fetchData}}),_c('dialog-editar-endpoint',{on:{"success":_vm.fetchData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }